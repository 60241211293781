import {
  bootstrapApplication,
  enableDebugTools,
} from '@angular/platform-browser';

import { AppComponent } from './pwa/app.component';

import { appConfig } from './pwa/app.config';

bootstrapApplication(AppComponent, appConfig)
  .then((ref) => {
    enableDebugTools(ref.components[0]);
  })
  .catch((err) => console.error(err));
