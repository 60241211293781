import { MetaReducer } from '@ngrx/store';

export const metaReducers: MetaReducer[] = [
  // ? Hydration
  /*
  // ! This will override the entire state with the hydrated state, which may not be what we want.
  (reducer: ActionReducer<State, Action>) =>
    (state: State | undefined, action: Action): State =>
      hydrationMetaReducerFunction(reducer(state, action), action),
  */
  // ? Logger
  /*
  // ! This will log all actions to the console.
  (reducer: ActionReducer<State, Action>) =>
    (state: State | undefined, action: Action): State => {
      console.log({ action, state });
      return reducer(state, action);
    },
    */
];
