import { AsyncPipe, NgClass, NgOptimizedImage } from '@angular/common';
import { Component, computed, HostBinding } from '@angular/core';
import { RouterLink } from '@angular/router';

import { Store } from '@ngrx/store';

import { AuthStore } from '@state/stores/auth.store';
import { UIStore } from '@state/stores/ui.store';

import { selectUrl } from '@selectors/router.selectors';

import { RegistrationTokenService } from '@services/tokens/registration-token.service';

import { LanguageSelectorComponent } from '../language-selector/language-selector.component';
import { LogoComponent } from '../logo/logo.component';
import { OnlineStatusComponent } from '../online-status/online-status.component';
import { ThemeSelectorComponent } from '../theme-selector/theme-selector.component';
import { UserMenuComponent } from '../user-menu/user-menu.component';
import { NotificationsComponent } from '@components/notifications/notifications.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    OnlineStatusComponent,
    NotificationsComponent,
    ThemeSelectorComponent,
    LanguageSelectorComponent,
    UserMenuComponent,
    NgClass,
    NgOptimizedImage,
    AsyncPipe,
    LogoComponent,
  ],
})
export class HeaderComponent {
  // Host bindings
  @HostBinding('class') classes = 'bg-white dark:bg-grey-vdark';

  // Properties
  url$ = this.store.select(selectUrl({ includeLanguage: false }));
  authenticated$ = computed(() => this.authStore.authenticated());
  registering$ = computed(
    () =>
      this.registrationTokenService.token$() !== undefined &&
      !this.authStore.registered()
  );
  routes: {
    path: string;
    label: string;
    icon?: string;
    authentication?: boolean;
  }[] = [
    /*
    {
      path: '/home',
      label: 'Home',
      icon: 'house',
    },
    */
    {
      path: '/chat',
      label: 'Chat',
      icon: 'messages',
      authentication: true,
    },
    {
      path: '/discovers',
      label: 'Discovers',
      icon: 'binoculars',
      authentication: true,
    },
  ];

  // Lifecycle hooks
  constructor(
    private store: Store,
    private authStore: AuthStore,
    private readonly uiStore: UIStore,
    private registrationTokenService: RegistrationTokenService
  ) {}
}
