<a class="mt-24 flex w-3/4 items-center justify-center">
  <app-logo></app-logo>
</a>

<p
  class="mt-14 text-center text-lg font-extralight text-grey-dark dark:text-grey-vlight"
>
  Chatprise works better when you install our app on your phone.
</p>

<app-store-links class="mt-14"></app-store-links>

<!--
<p class="text-md mt-14 text-center dark:text-white">
  I don't care, just let me use the web app.
</p>

<button class="mt-4 rounded-md bg-primary px-4 py-2 text-white" (click)="use()">
  Continue to Web App
</button>
-->
