<!-- Dropdown button -->
<button
  #dropdownButton
  type="button"
  title="User menu"
  class="relative mr-3 h-10 w-10 items-center justify-center rounded-full bg-grey-light text-sm focus:ring-4 focus:ring-grey dark:focus:ring-grey-dark md:mr-0"
  aria-expanded="false"
  [ngClass]="{
    hidden: !profile$(),
    flex: profile$(),
  }"
>
  @if (profile$(); as user) {
    <app-user-picture
      [id]="user.id"
      class="focus-within:opacity-75 hover:opacity-75"
    ></app-user-picture>

    <div
      class="absolute inset-0 flex items-center justify-center bg-primary-dark bg-opacity-50 opacity-0 transition-opacity hover:opacity-50 dark:bg-primary"
    ></div>
  }
</button>

<!-- Dropdown menu -->
<div
  #dropdown
  class="z-50 my-4 hidden list-none divide-y divide-grey-vlight rounded-lg border border-grey-vlight bg-white text-base shadow dark:divide-grey-dark dark:border-grey dark:bg-grey-dark"
  (click)="toggleDropdown()"
>
  @if (profile$(); as user) {
    <div class="bg-grey-vlight px-4 py-3 dark:bg-grey-vdark">
      <span class="block text-sm text-grey-vdark dark:text-white">{{
        user.fullName
      }}</span>
      <span class="block truncate text-sm text-grey dark:text-grey-light">{{
        user.email || user.id
      }}</span>
    </div>
    <ul class="py-2" aria-labelledby="user-dropdown-button">
      <li>
        <a
          [routerLink]="'/auth/profile'"
          class="block w-full px-4 py-2 text-left text-sm text-grey-dark hover:bg-grey-vlight dark:text-grey-light dark:hover:bg-grey-dark dark:hover:text-white"
          >Profile</a
        >
      </li>
      <!--
      TODO: Enable once we've enabled user settings
      <li>
        <a
          [routerLink]="'/settings'"
          class="block w-full px-4 py-2 text-left text-sm text-grey-dark hover:bg-grey-vlight dark:text-grey-light dark:hover:bg-grey-dark dark:hover:text-white"
          >Settings</a
        >
      </li>
      -->
      <li>
        <button
          (click)="logout()"
          class="block w-full px-4 py-2 text-left text-sm text-grey-dark hover:bg-grey-vlight dark:text-grey-light dark:hover:bg-grey-dark dark:hover:text-white"
        >
          Sign out
        </button>
      </li>
    </ul>
  }
</div>
