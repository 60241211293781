// ? https://ngrx.io/guide/router-store/selectors

import { Data, Params, Route } from '@angular/router';

import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

// `router` is used as the default feature name. You can use the feature name
// of your choice by creating a feature selector and pass it to the `getRouterSelectors` function
// export const selectRouter = createFeatureSelector<RouterReducerState>('yourFeatureName');

/*
export const {
  select, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectRouteDataParam, // factory function to select a route data param
  selectUrl, // select the current url
  selectTitle, // select the title if available
} = getRouterSelectors();
*/

export const RouterSelector =
  createFeatureSelector<RouterReducerState>('router');
const RouterSelectors = getRouterSelectors();

// ? Selects the current route
export const selectRoute = () =>
  createSelector(RouterSelector, RouterSelectors.selectCurrentRoute);

// ? Selects the current URL
export const selectUrl = (
  options: { includeLanguage?: boolean } = {
    includeLanguage: true,
  }
) =>
  createSelector(RouterSelector, RouterSelectors.selectUrl, (router, url) => {
    if (url) {
      if (options.includeLanguage) return url;
      else return url.replace(/\/[a-z]{2}\//, '/');
    } else return '/';
  });

// ? Selects the title of the current route
export const selectTitle = () =>
  createSelector(RouterSelector, RouterSelectors.selectTitle);

// ? Selects all the data of the current route (i.e. data defined in the route)
export const selectData = () =>
  createSelector(RouterSelector, RouterSelectors.selectRouteData);

// ? Selects a specific route data param
export const selectDataParam = (param: string) =>
  createSelector(
    RouterSelector,
    RouterSelectors.selectRouteData,
    (routeData: Data) => routeData[param]
  );

// ? Selects all the params of the current route
export const selectParams = () =>
  createSelector(RouterSelector, RouterSelectors.selectRouteParams);

// ? Selects a specific route param
export const selectParam = (param: string) =>
  createSelector(
    RouterSelector,
    RouterSelectors.selectRouteParams,
    (routeParams: Params) => routeParams[param]
  );

// ? Selects all the query params of the current route
export const selectQueryParams = () =>
  createSelector(RouterSelector, RouterSelectors.selectQueryParams);

// ? Selects a specific route query param
export const selectQueryParam = (param: string) =>
  createSelector(
    RouterSelector,
    RouterSelectors.selectQueryParams,
    (queryParams: Params) => queryParams[param]
  );

// ? Selects the current fragment of the current route
export const selectFragment = () =>
  createSelector(RouterSelector, RouterSelectors.selectFragment);

// ? Selects all the children of the current route
export const selectChildren = () =>
  createSelector(
    RouterSelector,
    RouterSelectors.selectCurrentRoute,
    (router, route: Route) => {
      if (route) {
        return route.children;
      }
      return undefined;
    }
  );

// ? Selects a specific child of the current route (by path or index)
export const selectChild = (child: string | number) =>
  createSelector(
    RouterSelector,
    RouterSelectors.selectCurrentRoute,
    (router, route: Route) => {
      if (route) {
        if (typeof child === 'string') {
          return route.children && route.children?.length > 0
            ? route.children.find((c) => c.path === child)
            : undefined;
        }
        return route.children && route.children?.length > child
          ? route.children[child]
          : undefined;
      }
      return undefined;
    }
  );

// ? Selects the first child of the current route
export const selectFirstChild = () =>
  createSelector(
    RouterSelector,
    RouterSelectors.selectCurrentRoute,
    (router, route: Route) => {
      if (route) {
        return route.children && route.children?.length > 0
          ? route.children[0]
          : undefined;
      }
      return undefined;
    }
  );

// ? Selects the language of the current route (using the `lang` route param)
export const selectLanguageFromURL = () =>
  createSelector(
    RouterSelector,
    RouterSelectors.selectRouteParams,
    (router, routeParams: Params): string | undefined => {
      if (routeParams) {
        return routeParams['lang'];
      }
      return undefined;
    }
  );
