<!-- Dropdown button -->
<button
  #dropdownButton
  id="language-dropdown-button"
  data-dropdown-toggle="language-dropdown"
  type="button"
  class="fill absolute flex items-center justify-center"
>
  @switch (content()) {
    @case ('flag') {
      <i class="fi" [class]="flag(language$()!.code)"></i>
    }
    @case ('icon') {
      <i class="fad fa-globe"></i>
    }
    @case ('code') {
      {{ language$()?.code | uppercase }}
    }
    @case ('name') {
      {{ language$()?.name | titlecase }}
    }
  }

  @if (showName()) {
    <span class="ml-3">
      {{ language$()?.name | titlecase }}
    </span>
  }
</button>

<!-- Dropdown menu -->
<div
  #dropdown
  id="language-dropdown"
  class="z-50 my-4 hidden list-none divide-y divide-grey-vlight rounded-lg bg-white text-base shadow dark:bg-grey-dark"
  (click)="toggleDropdown()"
>
  <ul class="py-2 font-medium" role="none">
    @for (lang of languages$(); track lang.code) {
      <li>
        <button
          type="button"
          role="menuitem"
          class="block w-full px-4 py-2 text-left text-sm text-grey-dark hover:bg-grey-vlight dark:text-grey-light dark:hover:bg-grey-vdark dark:hover:text-white"
          [ngClass]="{
            'text-primary dark:text-primary': lang.code === language$()?.code,
          }"
          (click)="changeLanguage(lang.code)"
        >
          <span class="flex items-center">
            <!--
          <img
            [src]="'assets/icon/' + language.code + '.png'"
            class="w-5 h-5 mr-2"
            alt="language icon"
          />
          -->
            <!-- TODO: Enable the flag icon when we've got the proper assets -->
            <!-- <i class="fi mr-3" [class]="flag(lang.code)"></i> -->
            {{ lang.name | titlecase }}
          </span>
        </button>
      </li>
    }
  </ul>
</div>
