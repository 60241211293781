import {
  AsyncPipe,
  NgClass,
  TitleCasePipe,
  UpperCasePipe,
} from '@angular/common';
import {
  Component,
  computed,
  ElementRef,
  HostBinding,
  input,
  signal,
  ViewChild,
} from '@angular/core';

import {
  asyncScheduler,
  filter,
  merge,
  observeOn,
  of,
  Subject,
  takeUntil,
} from 'rxjs';

import { Dropdown } from 'flowbite';

import { UIStore } from '@state/stores/ui.store';

import { LanguageService } from '@services/language/language.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  standalone: true,
  imports: [NgClass, AsyncPipe, TitleCasePipe, UpperCasePipe],
})
export class LanguageSelectorComponent {
  private destroy$ = new Subject<void>();

  // Host bindings
  @HostBinding('class') class = 'cursor-pointer relative';

  // Inputs & Outputs
  content = input.required<'flag' | 'icon' | 'code' | 'name'>();
  showName = input<boolean>(false);

  // Properties
  private dropdown?: Dropdown;
  @ViewChild('dropdown') private dropdownRef!: ElementRef<HTMLDivElement>;
  private get dropdownElement() {
    return this.dropdownRef.nativeElement;
  }
  @ViewChild('dropdownButton')
  private dropdownButtonRef!: ElementRef<HTMLButtonElement>;
  private get dropdownButtonElement() {
    return this.dropdownButtonRef.nativeElement;
  }

  language$ = computed(() => {
    const code = this.uiStore.language();
    if (!code) return;
    return {
      code,
      name: this.languageService.getLanguageName(code),
    };
  });
  languages$ = signal<{ code: string; name: string }[] | undefined>(
    this.languageService.supportedLanguages().map((language) => ({
      code: language,
      name: this.languageService.getLanguageName(language),
    }))
  );

  // Lifecycle hooks
  constructor(
    private uiStore: UIStore,
    private languageService: LanguageService
  ) {}

  ngAfterViewInit(): void {
    merge(of(this.dropdownElement), of(this.dropdownButtonElement))
      .pipe(
        takeUntil(this.destroy$),
        observeOn(asyncScheduler),
        filter(() =>
          [this.dropdownElement, this.dropdownButtonElement].every(
            (element) => !!element
          )
        )
      )
      .subscribe(() => this.createDropdown());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Methods
  private createDropdown() {
    if (this.dropdown) this.dropdown.destroy();
    this.dropdown = new Dropdown(
      this.dropdownElement,
      this.dropdownButtonElement,
      {
        placement: 'bottom',
        delay: 0,
      }
    );
  }

  toggleDropdown() {
    this.dropdown?.toggle();
  }

  emoji(language: string): string {
    return this.languageService.getRegionEmoji(language);
  }

  flag(language: string): string {
    return this.languageService.getLanguageFlag(language);
  }

  changeLanguage(language: string) {
    this.languageService.changeLanguage(language);
  }
}
