import { Routes } from '@angular/router';

import { SUPPORTED_LANGUAGES } from '@services/language/language.service';

import { AuthGuard } from '@guards/auth.guard';

import { HomeComponent } from '@views/home/home.component';
import { NotFoundComponent } from '@views/not-found/not-found.component';

export const routes: Routes = [
  // ? The '' route is necessary for Angular router to recognize routes without a language prefix
  ...(<Routes>['', ...SUPPORTED_LANGUAGES].map((lang) => ({
    ...(lang === ''
      ? { path: '' }
      : {
          matcher: (url) => {
            switch (true) {
              case url.length === 0:
                return { consumed: [] };
              case SUPPORTED_LANGUAGES.includes(url[0].path):
                return { consumed: [url[0]], posParams: { lang: url[0] } };
              default:
                return null;
            }
          },
        }),
    children: [
      // Default route
      { path: '', redirectTo: 'chat', pathMatch: 'full' },

      // App routes and lazy modules
      { path: 'home', component: HomeComponent },
      {
        path: 'auth',
        loadChildren: () => import('@routes/auth.routes'),
      },
      {
        path: 'chat',
        loadChildren: () => import('@routes/chat.routes'),
        canActivate: [AuthGuard],
      },
      {
        path: 'discovers',
        loadChildren: () => import('@routes/discovers.routes'),
        canActivate: [AuthGuard],
      },
      {
        path: 'legal',
        loadChildren: () => import('@routes/legal.routes'),
      },

      // Fallback route
      { path: 'error', component: NotFoundComponent },
      ...(lang !== '' ? [{ path: '**', redirectTo: 'error' }] : []),
    ],
  }))),

  // ? Fallback route
  { path: '**', redirectTo: () => 'error' },
];
