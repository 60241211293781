import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, map, Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { UIStore } from '@state/stores/ui.store';

export const SUPPORTED_LANGUAGES = ['ca', 'es', 'en', 'de', 'nl'];
const DEFAULT_LANGUAGE = 'en';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  constructor(
    private router: Router,
    private translateService: TranslateService,
    private uiStore: UIStore,
    private http: HttpClient
  ) {
    // Supported languages
    SUPPORTED_LANGUAGES.forEach((lang) =>
      this.addSupportedLanguage(lang, false)
    );

    // Set default language (from Browser, OS, IP, etc.)
    // TODO: When enabled, it automatically loads a language, but if the page is in another language, it does a weird glitch where it shows the page in the default language for a second and then it changes to the correct language
    // this.setDefaultLanguage();

    this.translateService.onLangChange.subscribe((languageChanged) => {
      console.debug('[Language Service] Language changed:', languageChanged);
      const language = languageChanged.lang;

      // ? Update the document HTML lang attribute
      document.documentElement.lang = language;

      // ? Update the URL without reloading the page when language changes
      this.router.navigate(
        [`${language}/${this.router.url.split('/').slice(2).join('/')}`],
        {
          queryParamsHandling: 'merge',
        }
      );
    });
  }

  addSupportedLanguage(lang: string, checkFile = true): void {
    if (checkFile && !this.validateLanguage(lang, true)) {
      throw new Error(`Language '${lang}' is not supported`);
    }
    this.translateService.addLangs([lang]);
  }

  supportedLanguages(): string[] {
    return this.translateService.getLangs();
  }

  async checkLanguageFile(lang: string): Promise<boolean> {
    const languageFile = `./assets/i18n/${lang}.json`;
    return this.http
      .get(languageFile)
      .toPromise()
      .then(() => true)
      .catch(() => false);
  }

  validateLanguage(lang: string, checkFile = false): boolean {
    if (!checkFile) {
      return this.supportedLanguages().includes(lang);
    }
    try {
      this.checkLanguageFile(lang);
      return SUPPORTED_LANGUAGES.includes(lang);
    } catch (error) {
      return false;
    }
  }

  changeLanguage(lang: string): void {
    if (isDevMode())
      console.debug('[Language Service] Changing language to:', lang);
    this.translateService.use(lang);
    this.uiStore.set('language', lang);
  }

  currentLanguage(): string {
    return this.translateService.currentLang || this.defaultLanguage();
  }

  defaultLanguage(): string {
    /*
    console.debug('Default Languages', {
      currentLang: this.translateService.currentLang,
      defaultLang: this.translateService.defaultLang,
      browserLang: this.translateService.getBrowserLang(),
    });
    */

    return (
      [
        // this.translateService.currentLang,
        this.translateService.defaultLang,
        this.translateService.getBrowserLang(),
      ].find((lang) => lang && this.validateLanguage(lang)) || DEFAULT_LANGUAGE
    );
  }

  setDefaultLanguage(options?: {
    language?: string;
    override?: boolean;
  }): void {
    if (options?.override || !this.translateService.defaultLang) {
      if (options?.language && this.validateLanguage(options.language))
        this.translateService.setDefaultLang(options.language);
      else if (options?.language) {
        console.warn(
          `Language '${
            options.language
          }' is not supported, using default language '${this.defaultLanguage()}'`
        );
        this.translateService.setDefaultLang(this.defaultLanguage());
      } else this.translateService.setDefaultLang(this.defaultLanguage());
    }
  }

  getLanguageFromIP(): Observable<string> {
    return this.http.get('https://ipinfo.io/json').pipe(
      map((response: any) => response.countryCode.toLowerCase()),
      catchError((err) => {
        console.warn('Error getting language from IP:', err);
        return this.translateService.getBrowserLang() || DEFAULT_LANGUAGE;
      })
    );
  }

  // ? This function returns the language string and region codes (ISO 639-1 and ISO 3166-1-alpha-2)
  getLocale(lang: string): { language: string; region: string } {
    // ? https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale
    // ? ISO 639-1 code: https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
    // ? ISO 3166-1-alpha-2 code: https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2

    if (
      // ? Test if the lang is a valid language tag (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#locales_argument)
      /^(?:(?:[a-z]{2,3}|[a-z]{5,8})(?:-[a-z]{4})?(?:-[a-z]{2}|\d{3})?(?:-[a-z0-9]{5,8}|\d[a-z0-9]{3})*(?:-[a-z0-9]{5,8})*(?:-[a-z0-9]+)*)?$/.test(
        lang
      )
    ) {
      // TODO: Validate language and region codes (ISO 639-1 and ISO 3166-1-alpha-2)
      const { language, region } = new Intl.Locale(lang);
      /*
      if (!region)
        console.warn(
          `Region not found for language '${lang}', returning its default region '${this.defaultRegion(
            language
          )}'`
        );
      */
      return { language, region: region || this.defaultRegion(language) };
    } else if (this.validateLanguage(lang)) {
      return {
        language: lang,
        region: this.defaultRegion(lang),
      };
    } else {
      console.warn(`Invalid language tag '${lang}'`);
      return {
        language: this.defaultLanguage(),
        region: this.defaultRegion(this.defaultLanguage()),
      };
    }
  }

  iso_3166_1_alpha_2(language: string): string {
    const locale = this.getLocale(language);
    return `${locale.region}-${locale.language}`;
  }

  defaultRegion(language: string): string {
    switch (language) {
      case 'ca':
        return 'ES';
      case 'es':
        return 'ES';
      case 'en':
        return 'US';
      case 'fr':
        return 'FR';
      case 'it':
        return 'IT';
      case 'de':
        return 'DE';
      case 'nl':
        return 'NL';
      default:
        return 'US';
    }
  }

  getRegionCode(language: string): string {
    return this.getLocale(language).region;
  }

  getRegionName(language: string, region?: string): string {
    const locale = this.getLocale(region ? language : `${language}-${region}`);
    language = locale.language;
    region = locale.region;

    // ? https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DisplayNames
    const internationalization = new Intl.DisplayNames([language], {
      type: 'region',
    });
    const country = internationalization.of(region || language);
    return country || '';
  }

  // ? Returns the flag emoji of the language
  getRegionEmoji(language: string): string {
    // ? Function that gets the country code and returns the emoji character for the flag
    return String.fromCodePoint(
      ...[...this.getRegionCode(language)].map((c) => c.charCodeAt(0) + 127397)
    );
  }

  getLanguageName(language: string): string {
    language = this.getLocale(language).language;

    // ? https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DisplayNames
    const internationalization = new Intl.DisplayNames([language], {
      type: 'language',
    });
    const languageName = internationalization.of(language);
    return languageName || '';
  }

  getLanguageFlag(language: string): string {
    const locale = this.getLocale(language);
    if (locale.region.toLowerCase() != locale.language.toLowerCase())
      // TODO: Revise this, because the language flag is not always the same as the region flag...
      // ? We should check if the region is a valid region, and if it is, return the region flag (en-US -> us, es-CT -> CT invalid region -> es-ct flag)
      // ? If not, return the language flag
      // return `fi-${this.iso_3166_1_alpha_2(language).toLowerCase()}`;
      return `fi-${locale.region.toLowerCase()}`;
    else return `fi-${locale.language}`;
  }
}
