<div class="flex h-full flex-col items-center justify-center">
  <h1 class="mb-8 text-4xl font-bold text-grey-vdark md:text-6xl">Error 404</h1>
  <p class="mb-8 text-lg text-grey-dark md:text-xl">
    Lo siento, la página que estás buscando no se pudo encontrar.
  </p>
  <a
    routerLink="/home"
    class="rounded bg-primary px-4 py-2 font-bold text-white hover:bg-primary-dark"
    >Volver a la página principal</a
  >

  <!--
  <div
    class="flex flex-col-reverse items-center justify-center gap-16 px-4 py-24 md:gap-28 md:px-44 md:py-20 lg:flex-row lg:px-24 lg:py-24"
  >
    <div class="relative w-full pb-12 lg:pb-0 xl:w-1/2 xl:pt-24">
      <div class="relative">
        <div class="absolute">
          <div class="">
            <h1 class="my-2 text-2xl font-bold text-grey-vdark">
              Looks like you've found the doorway to the great nothing
            </h1>
            <p class="my-2 text-grey-vdark">
              Sorry about that! Please visit our hompage to get where you need
              to go.
            </p>
            <a
              routerLink="/home"
              class="md my-2 rounded border bg-indigo-600 px-8 py-4 text-center text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50 sm:w-full lg:w-auto"
              >Take me there!</a
            >
          </div>
        </div>
        <div>
          <img src="https://i.ibb.co/G9DC8S0/404-2.png" />
        </div>
      </div>
    </div>
    <div>
      <img src="https://i.ibb.co/ck1SGFJ/Group.png" />
    </div>
  </div>
  -->
</div>
