<!-- ? This defer allows the app to hydrate in the "background" without the user noticing anything -->
<!-- ! But it also prevents dropdowns and other UI + JavaScript elements from our libraries to work as expected... -->
<!-- @defer (on timer(250ms)) { -->
<!-- <app-header></app-header> -->
<!-- ? Wrapping the router outlet inside a flex (with flex-1 and overflow-y-auto) is what allows us to have a full-size app with "sticky" header & footer -->
<main class="flex-1 overflow-x-hidden md:order-2">
  <router-outlet></router-outlet>
</main>

@if (authenticated$()) {
  <app-navigation-bar
    class="md:order-1 md:w-auto md:min-w-12 md:max-w-20 md:flex-col"
    [ngClass]="{
      'hidden md:flex': chat$(),
      'flex h-auto': chat$() === undefined,
    }"
  ></app-navigation-bar>
}
<!-- <app-footer></app-footer> -->

<!-- TODO: Show as a full-screen overlay with a button to close it (and never ask it again) -->
<!--
<app-store-view
  class="fill absolute z-50 standalone:hidden md:hidden"
></app-store-view>
-->
