<!-- App Store -->
<a [href]="appStore()" target="_blank">
  <img
    class="w-full"
    src="assets/images/stores/apple/Download-on-the-App-Store/en/Download_on_App_Store/Black_lockup/svg/Download_on_the_App_Store_Badge.svg"
    alt="App Store"
  />
</a>

<!-- Google Play Store -->
<a [href]="googlePlay()" target="_blank">
  <img
    class="w-full"
    src="assets/images/stores/google/download/en/svg/google-play-badge.svg"
    alt="Google Play Store"
  />
</a>
