<!-- Top section (routes) -->
<ul
  class="flex w-full flex-row items-center justify-around md:flex-col md:gap-4"
>
  <a [routerLink]="'/'" class="hidden items-center pb-4 md:flex">
    <app-logo [content]="'icon'" class="h-8 w-auto"></app-logo>
  </a>

  @for (route of routes$(); track route.path) {
    @if (!route.authentication || authenticated$()) {
      <li
        class="text-center"
        [ngClass]="{
          'md:hidden': route.top === 'mobile',
          'hidden md:block': route.top === 'desktop',
          'order-1 sm:order-2': route.path === '/discovers',
          'order-2 sm:order-1': route.path === '/chat',
          'order-3': route.path === '/auth/profile',
        }"
      >
        <a
          [routerLink]="route.path"
          class="flex h-10 w-10 items-center justify-center rounded-lg hover:bg-grey-vlight/50 dark:hover:bg-grey-dark/50"
          [ngClass]="{
            'text-primary dark:text-primary-light': url$() === route.path,
            'text-grey-dark hover:text-grey-vdark dark:text-grey-light dark:hover:text-white':
              url$() !== route.path,
          }"
        >
          @if (route.icon) {
            <div class="relative text-lg">
              <i [ngClass]="'fad fa-' + route.icon"></i>
              @if (route.badge && route.badge > 0) {
                <div
                  class="absolute left-1/2 top-[-0.75rem] flex h-[1.5rem] w-[1.5rem] items-center justify-center rounded-full bg-danger text-center text-xs font-bold leading-none text-white"
                >
                  {{ route.badge > 99 ? '99+' : route.badge }}
                </div>
              }
            </div>
          }
        </a>
      </li>
    }
  }
</ul>

<!-- Bottom section (only visible on desktop) -->
<ul class="hidden flex-col items-center gap-4 md:flex">
  <!-- Notifications (hide the icon if already granted) -->
  <!--
  @if (notifications$() !== 'granted') {
    <app-notifications
      class="h-10 w-10 rounded-lg hover:bg-grey-vlight/50 dark:hover:bg-grey-dark/50"
      [hideIfEnabled]="true"
      [colorize]="true"
    ></app-notifications>
  }
  -->
  <app-notifications
    class="h-10 w-10 rounded-lg text-grey-dark hover:bg-grey-vlight/50 hover:text-grey-vdark dark:text-grey-light dark:hover:bg-grey-dark/50 dark:hover:text-white"
    [colorize]="true"
  ></app-notifications>

  <app-online-status [colorize]="true"></app-online-status>

  <!-- Theme -->
  <app-theme-selector
    class="h-10 w-10 rounded-lg text-grey-dark hover:bg-grey-vlight/50 hover:text-grey-vdark dark:text-grey-light dark:hover:bg-grey-dark/50 dark:hover:text-white"
  ></app-theme-selector>

  <!-- Language Selector -->
  <app-language-selector
    class="h-10 w-10 rounded-lg text-grey-dark hover:bg-grey-vlight/50 hover:text-grey-vdark dark:text-grey-light dark:hover:bg-grey-dark/50 dark:hover:text-white"
    [content]="'code'"
  ></app-language-selector>

  <!-- App user menu -->
  <app-user-menu class="rounded-full border border-primary"></app-user-menu>
</ul>
