import { DatePipe } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { RouterLink } from '@angular/router';

import { configuration } from '@configuration/configuration';

import { version } from 'package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [RouterLink, DatePipe],
})
export class FooterComponent {
  // Host bindings
  @HostBinding('class') class = 'mt-auto bg-primary-dark py-4 text-white';

  // Properties
  version: string;
  copyright = configuration.title; // TODO: Replace with company's name
  currentDate: Date;

  // Lifecycle hooks
  constructor() {
    this.version = version;
    this.currentDate = new Date();
  }
}
