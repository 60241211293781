<button
  type="button"
  [title]="status$() !== 'granted' ? 'Ask permission' : 'Notifications enabled'"
  class="fill absolute flex items-center justify-center"
  (click)="requestPermission()"
>
  <i
    class="fad"
    [ngClass]="{
      'fa-bell-on':
        !hide().granted && (status$() === 'granted' || status$() === 'default'),
      'fa-bell-ring': !hide().default && status$() === 'default',
      'fa-bell-exclamation': !hide().denied && status$() === 'denied',

      'text-success': colorize() && status$() === 'granted',
      'text-danger': colorize() && status$() === 'denied',
    }"
  ></i>
</button>
