<!--
Sticky:
<nav
  class="bg-white dark:bg-grey-vdark fixed w-full z-20 top-0 left-0 border-b border-grey-light dark:border-grey"
>
-->
<nav
  class="custom-header-nav mx-auto flex flex-wrap items-center justify-between border-grey-light p-4 xl:px-10"
>
  <div class="flex items-center">
    <a [routerLink]="'/'" class="flex items-center">
      <app-logo class="h-8"></app-logo>
    </a>
    <app-online-status class="ml-8 hidden standalone:block"></app-online-status>
  </div>

  <div class="flex items-center gap-2 md:order-2">
    <!-- Notifications -->
    <app-notifications></app-notifications>

    <!-- Theme -->
    <app-theme-selector></app-theme-selector>

    <!-- Language Selector -->
    <app-language-selector [content]="'code'"></app-language-selector>

    <!-- Notifications -->
    <!--
    @if (user$ | async; as user) {
      <app-notifications-dropdown></app-notifications-dropdown>
    }
    -->

    <!-- User menu -->
    <app-user-menu class="hidden sm:block"></app-user-menu>

    <!-- Login Button -->
    <a
      [routerLink]="'/auth/login'"
      class="inline-flex items-center justify-center rounded-md bg-primary-dark px-4 py-2 text-sm text-white hover:bg-primary focus:bg-primary focus:outline-none"
      [ngClass]="{
        hidden: authenticated$() || registering$(),
      }"
      >Login</a
    >

    <!-- Hamburger button -->
    <button
      data-collapse-toggle="navbar-user"
      type="button"
      class="inline-flex h-10 w-10 items-center justify-center rounded-lg p-2 text-sm text-grey hover:bg-grey-vlight focus:outline-none focus:ring-2 focus:ring-grey-light dark:text-grey dark:hover:bg-grey-dark dark:focus:ring-grey-dark md:hidden"
      aria-controls="navbar-user"
      aria-expanded="false"
    >
      <span class="sr-only">Open main menu</span>
      <svg
        class="h-5 w-5"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 17 14"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M1 1h15M1 7h15M1 13h15"
        />
      </svg>
    </button>
  </div>

  <!-- Navigation -->
  <div
    class="hidden w-full items-center justify-between md:order-1 md:flex md:w-auto"
    id="navbar-user"
  >
    <ul
      class="mt-4 flex flex-col rounded-lg border border-grey-vlight bg-grey-vlight p-4 font-medium dark:border-grey dark:bg-grey-vdark md:mt-0 md:flex-row md:gap-16 md:border-0 md:bg-white md:p-0 md:dark:bg-grey-vdark"
    >
      @for (route of routes; track route.path) {
        @if (!route.authentication || authenticated$()) {
          <li>
            <a
              [routerLink]="route.path"
              [ngClass]="{
                'rounded bg-primary-dark text-white md:bg-transparent md:p-0 md:text-primary-dark md:dark:text-primary':
                  (url$ | async) === route.path,
                'block rounded py-2 pl-3 pr-4 text-grey-vdark hover:bg-grey-vlight dark:border-grey dark:text-white dark:hover:bg-grey-dark dark:hover:text-white md:p-0 md:hover:bg-transparent md:hover:text-primary-dark md:dark:hover:bg-transparent md:dark:hover:text-primary':
                  (url$ | async) !== route.path,
              }"
            >
              @if (route.icon) {
                <i class="fad mr-2" [ngClass]="'fa-' + route.icon"></i>
              }
              {{ route.label }}</a
            >
          </li>
        }
      }
    </ul>
  </div>
</nav>
