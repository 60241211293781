<p class="text-center">
  Copyright &copy; {{ currentDate | date: 'yyyy' }}
  <a routerLink="/terms-and-conditions" class="hover:text-primary-light"
    >Zonetacts</a
  >
  <!--
    <a
      href="http://zonetacts.com/condiciones-de-uso-y-politica-de-privacidad"
      target="_blank"
      class="underline"
      >Zonetacts</a
    >
    -->
  <span class="hidden md:inline-block">.</span>
  <span class="ml-2 hidden md:inline-block"> All rights reserved.</span>
</p>
<!-- <p class="text-center">All rights reserved.</p> -->
<!-- <p class="text-center">Version: {{ version }}</p> -->
