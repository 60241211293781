import { NgClass } from '@angular/common';
import { Component, HostBinding, isDevMode } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import {
  asyncScheduler,
  filter,
  from,
  interval,
  observeOn,
  switchMap,
  tap,
} from 'rxjs';

import { initFlowbite } from 'flowbite';

import { SemanticLevel } from '@models/semantic.enum';

import { AuthStore } from '@state/stores/auth.store';
import { UIStore } from '@state/stores/ui.store';

import { AuthService } from '@services/api/auth/auth.service';
import { NotificationsService } from '@services/notifications/notifications.service';
import { ToastService } from '@services/toast/toast.service';
import { AuthTokenService } from '@services/tokens/auth-token.service';

import { StoreViewComponent } from '@views/store-view/store-view.component';

import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationBarComponent } from '@components/navigation-bar/navigation-bar.component';

import { LanguagePrefixer } from '@services/language/prefixer.resolver';
import { AppService } from 'src/pwa/app.service';

import { UnreadChatsTitle } from '@strategies/unread-chats.strategy';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [
    HeaderComponent,
    RouterOutlet,
    NavigationBarComponent,
    FooterComponent,
    StoreViewComponent,
    NgClass,
  ],
})
export class AppComponent {
  // Host bindings
  // ? This is a flex container with a column direction that will take up the entire height of the screen.
  @HostBinding('class') class =
    'flex flex-col md:flex-row h-screen max-h-screen max-w-screen bg-grey-vlight dark:bg-grey-dark';

  // Properties
  authenticated$ = this.authStore.authenticated;
  chat$ = this.uiStore.chat;

  // Lifecycle hooks
  constructor(
    private readonly app: AppService,
    private readonly swUpdate: SwUpdate,
    private readonly toastService: ToastService,
    private readonly authService: AuthService,
    private readonly authTokenService: AuthTokenService,
    private readonly authStore: AuthStore,
    private readonly uiStore: UIStore,
    // ? Push notifications
    private readonly notificationsService: NotificationsService,
    // ? Resolvers (that need to be initialized)
    private readonly languagePrefixer: LanguagePrefixer,
    private readonly unreadChats: UnreadChatsTitle
  ) {
    // ? UI
    // initFlowbite();

    // ? Check stored token
    if (this.authTokenService.token != undefined)
      this.authService
        .checkToken()
        .pipe(
          observeOn(asyncScheduler),
          tap(() => this.app.load())
        )
        .subscribe();

    // ? Check app health
    /*
    interval(10000)
      .pipe(tap(() => this.app.check()))
      .subscribe();
    */
  }

  ngOnInit(): void {
    // ? Service Workers
    if (this.swUpdate.isEnabled) {
      // ? Check for updates every 15 minutes
      interval(1000 * 60 * 15, asyncScheduler)
        .pipe(
          switchMap(() =>
            from(this.swUpdate.checkForUpdate()).pipe(
              filter((available) => available === true),
              tap(() =>
                this.toastService.custom(
                  'New version available. Load New Version?',
                  {
                    type: SemanticLevel.Info,
                    position: 'top-center',
                    duration: 3000,
                    actions: [
                      {
                        text: 'Reload',
                        click: () => {
                          window.location.reload();
                        },
                      },
                    ],
                  }
                )
              )
            )
          )
        )
        .subscribe();
    }

    // ? UI
    initFlowbite();

    // ? Debugging
    if (isDevMode()) {
      // ? Toast notification
      /*
      this.toastService.custom(
        'Toast notification sent from the ToastService for debugging.',
        {
          type: SemanticLevel.Warning,
          position: 'top-center',
          duration: 10000,
          actions: [
            {
              text: 'Reload',
              click: () => {
                window.location.reload();
              },
            },
          ],
        }
      );
      */
      // ? Badges
      /*
      navigator.setAppBadge(4);
      let count = 0;
      setInterval(() => {
        count++;
        console.log('Setting badge...', count);
        navigator.setAppBadge(count);
      }, 1000);
      */
    }
  }
}
