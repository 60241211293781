import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';

import { provideRouterStore } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { TranslateModule } from '@ngx-translate/core';
import { provideHotToastConfig } from '@ngxpert/hot-toast';

import { configuration } from '@configuration/configuration';

import { metaReducers } from '@state/reducers/meta-reducers';
import { reducers } from '@state/reducers/reducers';
import { AuthStore } from '@state/stores/auth.store';
import { DiscoversStore } from '@state/stores/discovers.store';
import { MessagesStore } from '@state/stores/messages.store';
import { OTOsStore } from '@state/stores/otos.store';
import { RoomsStore } from '@state/stores/rooms.store';
import { UsersStore } from '@state/stores/users.store';

import { UIStore } from '@stores/ui.store';

import { NOTIFICATIONS_SUPPORTED } from '@services/notifications/notifications.service';

import { ZTSocket } from '@sockets/zonetacts/zonetacts.socket';

import { ZonetactsInterceptor } from '@interceptors/http/zonetacts.interceptor';

import { translationConfig } from '@services/language/translations';

import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    // ? Zoneless (https://angular.dev/guide/experimental/zoneless)
    provideExperimentalZonelessChangeDetection(),

    // ? Router + Strategies
    provideRouter(
      routes,
      // withRouterConfig({}),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      })
    ),
    // ? Uncomment to use a custom title strategy
    // { provide: TitleStrategy, useClass: PrefixedTitle },

    // ? HTTP + Interceptors
    provideHttpClient(
      withInterceptors([
        // ? Functional interceptors
      ]),
      // ? Class interceptors
      withInterceptorsFromDi()
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ZonetactsInterceptor,
      multi: true,
    },

    // ? Sockets
    ZTSocket,

    // ? Firebase
    ...(configuration.integrations?.firebase
      ? [
          provideFirebaseApp(() =>
            initializeApp(configuration.integrations.firebase!.config)
          ),

          // ? Firebase Cloud Messaging (Push notifications)
          // ! Push notifications will only work in browsers that support the Push API and the Notifications API
          // ! https://developer.mozilla.org/es/docs/Web/API/Push_API
          // ! https://developer.mozilla.org/es/docs/Web/API/Notifications_API
          ...(configuration.integrations.firebase.cloudMessaging &&
          NOTIFICATIONS_SUPPORTED
            ? [
                /*
                provideServiceWorker(
                  'service-workers/firebase-cloud-messaging.js',
                  {
                    enabled: true,
                    registrationStrategy: 'registerWhenStable:30000',
                    scope: '/firebase-cloud-messaging',
                  }
                ),
                */
                provideMessaging(() => getMessaging()),
              ]
            : []),
        ]
      : []),

    // ? Internationalization
    importProvidersFrom(TranslateModule.forRoot(translationConfig)),
    // TranslateService,
    // LanguageService,

    // ? State management
    // provideEffects(effects),
    provideStore(reducers, {
      metaReducers,
    }),
    provideRouterStore(),
    provideStoreDevtools({
      maxAge: 250,
      logOnly: !isDevMode(),
      connectInZone: true,
      trace: isDevMode(),
    }),
    AuthStore,
    UsersStore,
    OTOsStore,
    RoomsStore,
    DiscoversStore,
    MessagesStore,
    UIStore,

    // ? Alerts & Toasts
    provideHotToastConfig(),

    // ? Service workers
    provideServiceWorker('ngsw-worker.js', {
      // ! The ngsw-worker.js file is generated by the Angular CLI when the application is built based on the configuration in ngsw-config.json
      enabled: !isDevMode(),
      // ? Register the ServiceWorker as soon as the application is stable or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
