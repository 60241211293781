<button
  type="button"
  title="Toggle theme"
  class="fill absolute flex items-center justify-center"
  (click)="toggleTheme()"
>
  <i
    class="fad fa-lg"
    [ngClass]="{
      'fa-sun-bright': theme$() === Theme.Dark,
      'fa-moon-stars': theme$() === Theme.Light,
    }"
  ></i>
</button>
