import { DatePipe, NgClass } from '@angular/common';
import { Component, computed, HostBinding, signal } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';

import { filter } from 'rxjs';

import { AuthStore } from '@state/stores/auth.store';

import { ChatService } from '@services/api/chat.service';
import { NotificationsService } from '@services/notifications/notifications.service';

import { LogoComponent } from '../logo/logo.component';
import { UserMenuComponent } from '../user-menu/user-menu.component';
import { LanguageSelectorComponent } from '@components/language-selector/language-selector.component';
import { NotificationsComponent } from '@components/notifications/notifications.component';
import { OnlineStatusComponent } from '@components/online-status/online-status.component';
import { ThemeSelectorComponent } from '@components/theme-selector/theme-selector.component';

const INCLUDE_LANGUAGE = false;

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
  standalone: true,
  imports: [
    DatePipe,
    RouterLink,
    NgClass,
    OnlineStatusComponent,
    LanguageSelectorComponent,
    ThemeSelectorComponent,
    NotificationsComponent,
    UserMenuComponent,
    LogoComponent,
  ],
})
export class NavigationBarComponent {
  // Host bindings
  @HostBinding('class') class =
    'relative flex w-full flex-row justify-around border-t pt-3 pb-6 border-t-primary md:pt-6 md:pb-3 md:px-2 font-medium md:min-h-screen md:gap-12 md:flex-col md:justify-between md:border-r md:border-t-0 md:border-primary dark:md:border-primary-dark bg-primary-vlight dark:bg-primary-vdark';

  // Properties
  url$ = signal<string>('');
  authenticated$ = computed(() => this.authStore.authenticated());
  unread$ = computed(() => this.chatService.unreadChats$());
  notifications$ = this.notificationsService.status$;

  routes$ = computed(() => [
    {
      path: '/chat',
      label: 'Chat',
      icon: 'messages',
      authentication: true,
      top: true,
      badge: this.unread$(),
    },
    {
      path: '/discovers',
      label: 'Discovers',
      icon: 'binoculars',
      authentication: true,
      top: true,
    },
    {
      path: '/auth/profile',
      label: 'Profile',
      icon: 'user',
      top: 'mobile',
      bottom: true,
      authentication: true,
    },
  ]);

  // Lifecycle hooks
  constructor(
    private authStore: AuthStore,
    private router: Router,
    private readonly chatService: ChatService,
    private readonly notificationsService: NotificationsService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (INCLUDE_LANGUAGE) this.url$.set(this.router.url);
        else this.url$.set(this.router.url.replace(/\/[a-z]{2}\//, '/'));
      });
  }
}
