// ! This is necessary for our Router to behave properly when a language code is not present in the URL
// ! It is injected in the app.routing.module.ts file, where the service is initialized
// ! This prevents the router from returning an error in routes like '/home' or '/auth/login' without a language code

import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { LanguageService } from '@services/language/language.service';

@Injectable({ providedIn: 'root' })
export class LanguagePrefixer {
  constructor(
    private router: Router,
    private languageService: LanguageService
  ) {
    // Get (and validate) language from URL
    this.router.events.subscribe((event) => {
      // ? Get the current language
      const lang = this.languageService.currentLanguage();

      // console.debug('Navigation Event', event);
      switch (true) {
        /*
        case event instanceof NavigationStart:
          event = event as NavigationStart;
          {
            const param =
              event.url.split('/').find((param) => param.length > 1) || ''; // Extract first param from the URL
            if (!this.languageService.validateLanguage(param, false)) {
              switch (true) {
                // TODO: Replace this length check with a proper ISO 639-1 validation to validate if it's a language code
                case param.length === 2:
                  // ? If the URL contains a language code, but it's not valid, redirect to the default language
                  console.debug(
                    `[Prefixer Resolver] '${param}' language not supported. Redirecting to:`,
                    `${event.url.replace(param, lang)}`
                  );

                  // this.router.navigate([event.url.replace(param, lang)], {});
                  this.router.navigate(
                    [
                      lang,
                      ...event.url
                        .split('/')
                        .filter((p) => p.length > 0 && p !== param),
                    ],
                    {}
                  );
                  break;

                case param.length !== 2:
                default:
                  console.debug(
                    '[Prefixer Resolver] Language not found in URL. Redirecting to:',
                    `/${lang}${event.url.length > 1 ? event.url : ''}`
                  );
                  this.router.navigate(
                    [lang, ...event.url.split('/').filter((p) => p.length > 0)],
                    {}
                  );
                  break;
              }
            }
          }
          break;
        */
        case event instanceof NavigationEnd:
          event = event as NavigationEnd;
          {
            // ? This is necessary to update the language when the user navigates to a URL with a specific language code
            // ! It needs to be here or the child route fallbacks won't work (the auth fallback to login, for example, won't work, and will fallback to the root fallback instead, which is home)
            const param = event.urlAfterRedirects
              .split('/')
              .filter((segment) => segment)[0];

            if (this.languageService.validateLanguage(param, false))
              this.languageService.changeLanguage(param);
            // ? Add the language param to the URL
            else
              this.router.navigate(
                [
                  lang,
                  ...event.urlAfterRedirects
                    .split('/')
                    .filter((segment) => segment),
                ],
                {
                  replaceUrl: true,
                }
              );
          }
          break;
        default:
          break;
      }
    });
  }
}
