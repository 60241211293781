import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

import { AppError, instanceOfAppError } from '@errors/app.error';
import { StandardError } from '@errors/standard.error';

@Injectable({ providedIn: 'root' })
export class AlertService {
  constructor(private translateService: TranslateService) {}

  showModalError(error: any, showCode?: boolean): void {
    let message = '';
    switch (true) {
      case error instanceof StandardError:
        message = `${showCode ? error.code + ': ' : ''}${error.message}`;
        break;
      case error instanceof AppError:
      case instanceOfAppError(error):
        message = `${showCode ? error.code + ': ' : ''}${
          error.detail[this.translateService.currentLang] || error.detail['en']
        }`;
        break;
      case error instanceof HttpErrorResponse:
        message = `${showCode ? error.statusText + ': ' : ''}${error.error}`;
        break;
      default:
        switch (typeof error) {
          case 'string':
            message = error;
            break;
          case 'object':
            if (Object.keys(error).includes('message')) {
              if (showCode && Object.keys(error).includes('code')) {
                message = `${error.code}: ${error.message}`;
              }
              message = error.message;
            } else {
              message = JSON.stringify(error);
            }
            break;
          default:
            try {
              message = error.toString();
            } catch (e) {
              message = 'Unknown error';
            }
        }
    }

    Swal.fire({
      title: 'Error',
      text: message,
      // text: error.code + ": " + error.message,
      icon: 'error',
    }).then(() => {});
  }
}
