import { computed, effect, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ChatService } from '@services/api/chat.service';

@Injectable({
  providedIn: 'root',
})
export class UnreadChatsTitle {
  // Properties
  unread$ = computed(() => this.chatService.unreadChats$());

  // Lifecycle hooks
  constructor(
    private title: Title,
    private readonly chatService: ChatService
  ) {
    effect(() => {
      this.prefixTitle(this.unread$());
    });
  }

  // Methods
  prefixTitle(chats: number): void {
    const current = this.title.getTitle();

    if (chats === 0) {
      this.title.setTitle(current.replace(/^\(\d+\) /, ''));
    } else if (current.match(/^\(\d+\)/))
      this.title.setTitle(current.replace(/^\(\d+\)/, `(${chats})`));
    else this.title.setTitle(`(${chats}) ${current}`);
  }
}
