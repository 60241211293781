import { AsyncPipe, NgClass } from '@angular/common';
import { Component, HostBinding, input } from '@angular/core';

import { SemanticLevel } from '@models/semantic.enum';

import { NotificationsService } from '@services/notifications/notifications.service';
import { ToastService } from '@services/toast/toast.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  standalone: true,
  imports: [NgClass, AsyncPipe],
})
export class NotificationsComponent {
  // Host bindings
  @HostBinding('class') class = 'cursor-pointer relative';

  // Inputs & Outputs
  colorize = input<boolean>(false);
  hide = input({
    granted: false,
    denied: false,
    default: false,
  });

  // Properties
  status$ = this.notificationsService.status$;

  // Lifecycle hooks
  constructor(
    private notificationsService: NotificationsService,
    private toastService: ToastService
  ) {}

  // Methods
  requestPermission(): void {
    switch (this.notificationsService.status$()) {
      case 'granted':
        return this.notificationsService.test();
      case 'denied':
        return this.toastService.custom(
          'Permissions was already asked and denied. Check your browser settings...',
          {
            id: 'notifications-denied',
            type: SemanticLevel.Warning,
            position: 'top-center',
            dismissible: true,
          }
        );
      case 'default':
      default:
        this.notificationsService.request().subscribe();
    }
  }
}
