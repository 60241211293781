import { AppError } from './app.error';

export class StandardError {
  readonly original: any;
  readonly status: number;
  readonly code: string;
  readonly message: string;
  readonly appError?: AppError;

  constructor(
    original: any,
    status: number,
    code: string,
    message: string,
    appError?: AppError
  ) {
    this.original = original;
    this.status = status;
    this.code = code;
    this.message = message;
    this.appError = appError;
  }
}
