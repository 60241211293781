@defer (on timer(50ms)) {
  <i
    class="fad"
    [ngClass]="{
      'fa-wifi-exclamation': browser$() === false,
      'fa-cloud-exclamation': browser$() && server$() === false,
      'fa-cloud-check': browser$() && server$(),

      'text-success': colorize() && browser$() && server$(),
      'text-danger':
        colorize() && (browser$() === false || server$() === false),
    }"
    title="{{
      browser$() === false
        ? 'No internet connection'
        : server$() === false
          ? 'Server is offline'
          : 'Online'
    }}"
  ></i>
}
