import { AsyncPipe, NgClass } from '@angular/common';
import {
  Component,
  computed,
  ElementRef,
  HostBinding,
  ViewChild,
} from '@angular/core';
import { RouterLink } from '@angular/router';

import { asyncScheduler, merge, observeOn, of, Subject, takeUntil } from 'rxjs';

import { Store } from '@ngrx/store';

import { Dropdown } from 'flowbite';

import { AuthStore } from '@state/stores/auth.store';
import { UsersStore } from '@state/stores/users.store';

import { selectUrl } from '@selectors/router.selectors';

import { AuthService } from '@services/api/auth/auth.service';

import { UserPictureComponent } from '../user-picture/user-picture.component';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  standalone: true,
  imports: [NgClass, UserPictureComponent, RouterLink, AsyncPipe],
})
export class UserMenuComponent {
  private destroy$ = new Subject<void>();

  // Host bindings
  @HostBinding('class') class = 'overflow-hidden';

  // Properties
  url$ = this.store.select(selectUrl({ includeLanguage: false }));
  private me$ = this.authStore.me;
  profile$ = computed(() =>
    this.me$() ? this.usersStore.get(this.me$()!) : undefined
  );

  private dropdown?: Dropdown;
  @ViewChild('dropdown', { static: false })
  private dropdownRef!: ElementRef<HTMLDivElement>;
  private get dropdownElement() {
    return this.dropdownRef.nativeElement;
  }
  @ViewChild('dropdownButton', { static: false })
  private dropdownButtonRef!: ElementRef<HTMLButtonElement>;
  private get dropdownButtonElement(): HTMLButtonElement {
    return this.dropdownButtonRef.nativeElement;
  }

  // Lifecycle hooks
  constructor(
    private store: Store,
    private readonly authStore: AuthStore,
    private readonly usersStore: UsersStore,
    private readonly authService: AuthService
  ) {}

  ngAfterViewInit(): void {
    merge(of(this.dropdownElement), of(this.dropdownButtonElement))
      .pipe(takeUntil(this.destroy$), observeOn(asyncScheduler))
      .subscribe(() => this.createDropdown());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Methods
  private createDropdown() {
    if (this.dropdown) this.dropdown.destroy();
    this.dropdown = new Dropdown(
      this.dropdownElement,
      this.dropdownButtonElement,
      {
        placement: 'top',
        delay: 0,
      }
    );
  }

  logout() {
    this.authService.logout().pipe(observeOn(asyncScheduler)).subscribe();
  }

  toggleDropdown() {
    this.dropdown?.toggle();
  }
}
